import React, {useState, useEffect, useMemo, useRef} from "react";
import {getAffiliateGroups} from "../api/affiliateGroups";
import {getModuleDetails} from "../api/module";
import DataTable from "react-data-table-component";
import FilterDatatable from "../components/FilterDatatable";
import toast from 'react-hot-toast'
import {createMerchantAffiliate, getMerchantAffiliates, updateMerchantAffiliate} from "../api/merchantAffiliates";
import {getMerchants} from "../api/merchants";
import AsyncSelect from 'react-select/async';

const MerchantAffiliates = () => {
    /* token and headers*/
    const token = localStorage.getItem('accessToken');
    if (token === '' || token === undefined || token === null) {
        localStorage.setItem('accessToken', '');
        localStorage.setItem('menuAccess', []);
        localStorage.setItem('profileRole', '');
        window.location.href = '/login';
    }

    /* session details */
    const role = localStorage.getItem('profileRole');
    const userId = localStorage.getItem('userId');

    /* page data */
    const INITIAL_FORM_DATA = {
        affiliate_group_id: '',
        merchant_id: '',
        is_affiliatedotcom: false,
        is_primary: false,
        primary_link: '',
    }
    const addEditModalRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [module, setModule] = useState(null);
    const [originalList, setOriginalList] = useState([]);
    const [list, setList] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [viewSelectedRow, setViewSelectedRow] = useState(false);
    const [affiliateGroups, setAffiliateGroups] = useState([]);
    const [merchants, setMerchants] = useState([]);
    // Search Filter
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [currentFilters, setCurrentFilters] = useState({search: ''});

    const columns = [
        {
            name: "AFFILIATE GROUP",
            cell: (row) => <span className='text-wrap'>{row.affiliate_group?.affiliate_group_name || ''}</span>,
        },
        {
            name: "MERCHANT NAME",
            cell: (row) => <span className='text-wrap'>{row.merchant?.merchant_name || ''}</span>,
        },
        {
            name: "CREATED DATE",
            cell: (row) => <span className='text-wrap'>{formatDate(row.created_at)}</span>,
        },
        {
            name: "UPDATED DATE",
            cell: (row) => <span className='text-wrap'>{formatDate(row.updated_at)}</span>,
        },
        {
            name: "IS AFFILIATE.COM",
            cell: (row) => <span className='text-wrap'>{row.is_affiliatedotcom ? 'Yes' : 'No'}</span>,
        },
        {
            name: "IS PRIMARY",
            cell: (row) => <span className='text-wrap'>{row.is_primary ? 'Yes' : 'No'}</span>,
        },
        {
            name: "PRIMARY LINK",
            cell: (row) => <span className='text-wrap'>{row.primary_link || ''}</span>,
        },
        {
            name: "",
            cell: (row) => <div>
                <a className='me-3' href="#" onClick={() => {
                    setSelectedRow(row);
                }} data-bs-toggle="modal" data-bs-target="#addEditRow">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 9.49998V12H2.5L9.87333 4.62665L7.37333 2.12665L0 9.49998ZM11.8067 2.69332C11.8685 2.63164 11.9175 2.55838 11.951 2.47773C11.9844 2.39708 12.0016 2.31063 12.0016 2.22331C12.0016 2.136 11.9844 2.04955 11.951 1.9689C11.9175 1.88825 11.8685 1.81499 11.8067 1.75331L10.2467 0.193315C10.185 0.131512 10.1117 0.0824806 10.0311 0.0490263C9.95043 0.015572 9.86398 -0.00164795 9.77667 -0.00164795C9.68935 -0.00164795 9.6029 0.015572 9.52225 0.0490263C9.4416 0.0824806 9.36834 0.131512 9.30667 0.193315L8.08667 1.41331L10.5867 3.91331L11.8067 2.69332Z"
                            fill="#121872"/>
                    </svg>
                </a>
                {/*<a href="#" onClick={() => {*/}
                {/*    setSelectedRow(row)*/}
                {/*}} data-bs-toggle="modal" data-bs-target="#deletePromo">*/}
                {/*    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*        <path*/}
                {/*            d="M2.16675 12C1.80008 12 1.4863 11.8696 1.22541 11.6087C0.964526 11.3478 0.833859 11.0338 0.833415 10.6667V2H0.166748V0.666667H3.50008V0H7.50008V0.666667H10.8334V2H10.1667V10.6667C10.1667 11.0333 10.0363 11.3473 9.77542 11.6087C9.51453 11.87 9.20053 12.0004 8.83342 12H2.16675ZM3.50008 9.33333H4.83342V3.33333H3.50008V9.33333ZM6.16675 9.33333H7.50008V3.33333H6.16675V9.33333Z"*/}
                {/*            fill="#121872"/>*/}
                {/*    </svg>*/}
                {/*</a>*/}
            </div>,
        }
    ];

    useEffect(() => {
        setIsLoading(true);
        loadPageData();
    }, []);

    const loadPageData = async () => {
        const [module, merchantAffiliates, affiliateGroups, merchants] = await Promise.all([
            getModuleDetails(userId, window.location.pathname),
            getMerchantAffiliates(),
            getAffiliateGroups({sorting: 'affiliate_group_name'}),
            getMerchants({sorting: 'merchant_name'}),
        ])
        setModule(module.menu_access[0]);
        setOriginalList(merchantAffiliates.data);
        setList(merchantAffiliates.data);
        setAffiliateGroups(affiliateGroups.data);
        setMerchants(merchants.data);
        setIsLoading(false);
    }

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterDatatable
                onFilter={e => {
                    setCurrentFilters({
                        ...currentFilters,
                        search: e.target.value,
                    });
                    setFilterText(e.target.value);
                }}
                onClear={handleClear}
                filterText={filterText}
                placeholder={'Search label'}
            />
        );
    }, [filterText, resetPaginationToggle]);

    // Automatically update table rows based on filters
    useEffect(() => {
        let tempList = [...originalList];
        const filters = Object.keys(currentFilters);
        for (let i = 0; i < filters.length; i++) {
            const filterType = filters[i];
            const filterValue = currentFilters[filterType];

            if (filterType === 'search') {
                if (filterValue) {
                    const normalizedFilter = filterValue.toLowerCase();
                    tempList = tempList.filter(row => {
                        const name = row.affiliate_group?.affiliate_group_name;
                        const merchant = row.merchant?.merchant_name;
                        if (!name && !merchant) return false;
                        return (name.toLowerCase().includes(normalizedFilter) || merchant.includes(normalizedFilter));
                    });
                }
            }
        }
        setList(tempList);
    }, [currentFilters, originalList]);

    function formatDate(date) {
        const d = new Date(date);
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${month}/${day}/${year}`;
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setSelectedRow((selectedRow) => ({
            ...selectedRow,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSaving(true);

        if (!selectedRow.affiliate_group_id || !selectedRow.merchant_id) {
            toast.error("Required: Affiliate Group & Merchant.");
            setIsSaving(false);
            return;
        }

        try {
            if (selectedRow.merchant_affiliate_id) {
                delete selectedRow.affiliate_group;
                delete selectedRow.merchant;
                await updateMerchantAffiliate(selectedRow, selectedRow.merchant_affiliate_id);
            } else {
                await createMerchantAffiliate(selectedRow);
            }
            const merchantAffiliates = await getMerchantAffiliates();
            setOriginalList(merchantAffiliates.data)
            setList(merchantAffiliates.data);
            if (addEditModalRef.current) window.$(addEditModalRef.current).modal("hide"); // Use jQuery to hide the modal
            if (selectedRow.merchant_affiliate_id) {
                toast.success("Merchant affiliate updated!");
            } else {
                toast.success("Merchant affiliate created!");
            }
            setSelectedRow(INITIAL_FORM_DATA);
        } catch (e) {
            if (selectedRow.merchant_affiliate_id) {
                console.error("Unable to update merchant affiliate. Error: ", e);
                toast.error("Unable to update merchant affiliate.", 'Oops!');
            } else {
                console.error("Unable to create new merchant affiliate. Error: ", e);
                toast.error("Unable to create new merchant affiliate.", 'Oops!');
            }
        }
        setIsSaving(false);
    }

    const loadAffiliateGroupOptions = async (inputValue, callback) => {
        if (!inputValue) {
            callback(affiliateGroups);
            return;
        }
        callback(affiliateGroups.filter(ag => (ag.affiliate_group_name.toLowerCase().includes(inputValue.toLowerCase()))));
    };

    const loadMerchantOptions = async (inputValue, callback) => {
        if (!inputValue) {
            callback(merchants);
            return;
        }
        callback(merchants.filter(ag => (ag.merchant_name.toLowerCase().includes(inputValue.toLowerCase()))));
    };

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="main-kpis">
            </div>

            <div className="row mb-12">
                <div id="table-list-container" className="col-md-12">
                    <div className="card">
                        <h5 className="card-header">
                            {
                                (module?.can_add === 1) &&
                                <button type="submit"
                                        className="btn btn-primary btn-sm ms-2 mb-2 btn-create-promotion "
                                        data-bs-toggle="modal" data-bs-target="#addEditRow"
                                        onClick={() => setSelectedRow({})}>
                                    Add
                                    {/*&nbsp; <i className="bx bxs-plus-circle gh-h-px-20"></i>*/}
                                </button>
                            }
                            <div className="kpi-card-info">
                                <span>Gyfthint</span>
                                <p>Merchant Affiliates</p>
                            </div>
                            <hr/>
                            <div className='d-flex'>
                            </div>
                        </h5>
                        <div className="table-responsive text-nowrap">
                            <DataTable
                                columns={columns}
                                data={list}
                                highlightOnHover
                                // onRowSelected={handleRowSelected}
                                // noDataComponent={<div className="w-100">
                                //     <table className="table">
                                //         <thead>
                                //         <tr>
                                //             {
                                //                 promotion_columns.filter(c => !c.omit).map(c => (<th>{c.name}</th>))
                                //             }
                                //         </tr>
                                //         </thead>
                                //         <tbody>
                                //         <tr>
                                //             <td colSpan={10}>No records found.</td>
                                //         </tr>
                                //         </tbody>
                                //     </table>
                                // </div>}
                                persistTableHead
                                pagination
                                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="addEditRow" tabIndex="-1" aria-hidden="true" ref={addEditModalRef}>
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            <div className="mb-6">
                                <h4 className="mb-2">Merchant Affiliate Details</h4>
                            </div>
                            <hr/>
                            <form className="row g-6">
                                <div className="col-12 col-md-4">
                                    {
                                        !!(selectedRow.merchant_affiliate_id) &&
                                        <>
                                            <label className="form-label" htmlFor="merchant_affiliate_id">
                                                Merchant Affiliate ID:
                                            </label>
                                            <p>{selectedRow.merchant_affiliate_id}</p>
                                        </>
                                    }
                                    <label className="form-label" htmlFor="affiliate_group_id">
                                        Affiliate Group:
                                    </label>
                                    <AsyncSelect
                                        value={selectedRow?.affiliate_group ? selectedRow.affiliate_group : ''}
                                        cacheOptions
                                        loadOptions={loadAffiliateGroupOptions}
                                        defaultOptions={affiliateGroups}
                                        getOptionLabel={(option) => (option.affiliate_group_name)}
                                        getOptionValue={(option) => (option.affiliate_group_id)}
                                        onChange={(option) => {
                                            handleInputChange({
                                                target: {
                                                    name: 'affiliate_group_id',
                                                    value: option ? option.affiliate_group_id : '',
                                                },
                                            })
                                            handleInputChange({
                                                target: {
                                                    name: 'affiliate_group',
                                                    value: option ? option : '',
                                                },
                                            })
                                        }}
                                        name={'affiliate_group_id'}
                                        id={'affiliate_group_id'}
                                        isFocused={true}
                                        classNamePrefix={'gh_react_select'}
                                        placeholder={'Select Affiliate Group'}
                                    />
                                    <label className="form-label" htmlFor="merchant_id">
                                        Merchant:
                                    </label>
                                    <AsyncSelect
                                        value={selectedRow?.merchant ? selectedRow.merchant : ''}
                                        cacheOptions
                                        loadOptions={loadMerchantOptions}
                                        defaultOptions={merchants}
                                        getOptionLabel={(option) => (option.merchant_name)}
                                        getOptionValue={(option) => (option.merchant_id)}
                                        onChange={(option) => {
                                            handleInputChange({
                                                target: {
                                                    name: 'merchant_id',
                                                    value: option ? option.merchant_id : '',
                                                },
                                            })
                                            handleInputChange({
                                                target: {
                                                    name: 'merchant',
                                                    value: option ? option : '',
                                                },
                                            })
                                        }}
                                        name={'merchant_id'}
                                        id={'merchant_id'}
                                        isFocused={true}
                                        classNamePrefix={'gh_react_select'}
                                        placeholder={'Select Merchant'}
                                    />
                                    <label className="form-label" htmlFor="is_affiliatedotcom">
                                        Is Affiliate.com:
                                    </label>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch"
                                               id="is_affiliatedotcom" name="is_affiliatedotcom"
                                               checked={selectedRow?.is_affiliatedotcom ? selectedRow.is_affiliatedotcom : false}
                                               onChange={(event) => {
                                                   handleInputChange({
                                                       target: {
                                                           name: 'is_affiliatedotcom',
                                                           value: event.target.checked,
                                                       },
                                                   })
                                               }}/>
                                    </div>
                                    <label className="form-label" htmlFor="is_primary">
                                        Is Primary:
                                    </label>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch"
                                               id="is_primary" name="is_primary"
                                               checked={selectedRow?.is_primary ? selectedRow.is_primary : false}
                                               onChange={(event) => {
                                                   handleInputChange({
                                                       target: {
                                                           name: 'is_primary',
                                                           value: event.target.checked,
                                                       },
                                                   })
                                               }}/>
                                    </div>
                                    <label className="form-label" htmlFor="primary_link">
                                        Primary Link:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="primary_link" name="primary_link"
                                           value={selectedRow.primary_link || ''}
                                           onChange={handleInputChange}/>
                                </div>
                                <div className="col-12 col-md-4">
                                    {
                                        !!(selectedRow.merchant_affiliate_id) &&
                                        <>
                                            <label className="form-label" htmlFor="affiliate_group_id">
                                                Created Date:
                                            </label>
                                            <p>{formatDate(selectedRow.created_at)}</p>
                                            <label className="form-label" htmlFor="affiliate_group_id">
                                                Updated Date:
                                            </label>
                                            <p>{formatDate(selectedRow.updated_at)}</p>
                                        </>
                                    }
                                </div>
                            </form>
                            <hr/>
                            <div className="col-12">
                                {
                                    !viewSelectedRow &&
                                    <>
                                        {
                                            !isSaving ?
                                                <button type="submit" className="btn btn-primary me-3"
                                                        onClick={handleSubmit}>
                                                    {!!(selectedRow.merchant_affiliate_id) ? 'Update' : 'Create'}
                                                </button>
                                                :
                                                <button type="submit" disabled className="btn btn-primary me-3"
                                                >Saving...</button>
                                        }
                                    </>
                                }
                                <button type="reset" className="btn btn-label-secondary" data-bs-dismiss="modal"
                                        aria-label="Close">Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MerchantAffiliates
