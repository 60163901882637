import direct_axios from 'axios';
import axios from "./axios";

const token = localStorage.getItem('accessToken');
const config = {headers: {Authorization: `Bearer ${token}`}};

const external_api = process.env.REACT_APP_EXTERNAL_API_ENDPOINT;
const defaultPagination = {
    page: 1,
    limit: 9999
};

export async function getMerchants(options = {}) {
    const url = new URL(external_api + '/merchants');

    const params = {...defaultPagination, ...options};
    for (const key of Object.keys(params)) {
        url.searchParams.append(key, params[key]);
    }

    const result = await direct_axios.get(url.toString(), config);
    return result.data;
}
