import React, { useEffect, useState } from "react";
import axios from "../api/axios";
import direct_axios from 'axios';
import ValueBox from "../components/ValueBox";
import {getAffiliateCodesByStoreUuid} from "../api/affiliateCodes";
import {getOrdersByStoreName, getOrdersByStoreUuid} from "../api/orders";
import {getCustomers, getCustomersByStoreName} from "../api/users";

const GifthintValue = () => {
  /* token and headers*/

  const currentActiveStoreId = localStorage.getItem('currentActiveStoreId');
  const currentActiveStoreName = localStorage.getItem('currentActiveStore');
  let store_id = currentActiveStoreId === '' || currentActiveStoreId === undefined ? 1: currentActiveStoreId;
  let store_name = currentActiveStoreName === '' || currentActiveStoreName === undefined ? '': currentActiveStoreName;
  const [storeId, setStoreId] = useState(store_id);
  const [storeName, setStoreName] = useState(store_name);
  const [role, setRole] = useState(localStorage.getItem('profileRole'));

  const [isLoading, setIsLoading] = useState(false);
  const [totalGMV, setTotalGMV] = useState(0);
  const [totalCustomer, setTotalCustomer] = useState(0);
  const [averageOrderValue, setAverageOrderValue] = useState(0);

  const token = localStorage.getItem('accessToken');
  if(token === '' || token === undefined || token === null){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }

  useEffect(() => {
    fetchTable();
    setIsLoading(true);
  }, []);

  const fetchTable = async () => {
    // Set loading state and clear previous orders
    setIsLoading(true);
    // let external_url = store_id===null?external_api+'/order':external_api+'/order?store='+storeName+'&status=PURCHASED';
    try {
      const [orders, customers] = await Promise.all([
        getOrdersByStoreName(storeName, role === 'merchant' ? {status: 'PURCHASED'} : {}),
        getCustomersByStoreName(storeName)
      ])
      const totalOrder = orders.data.length;
      const total = orders.data.reduce((sum, product) => {
        // Check if the product's hint is not 'CANCEL' before adding its price
        if (product.status !== 'CANCEL') {
          return sum + product.hint.price;
        }
        return sum;
      }, 0);
      setTotalGMV(total);

      setTotalCustomer(customers.data.length);
      setAverageOrderValue(total/totalOrder);
      setIsLoading(false);

      // await direct_axios.get(external_url, config ).then(result => {
      //   const totalOrder = result.data.data.length;
      //   const total = result.data.data.reduce((sum, product) => {
      //     // Check if the product's hint is not 'CANCEL' before adding its price
      //     if (product.status !== 'CANCEL') {
      //       return sum + product.hint.price;
      //     }
      //     return sum;
      //   }, 0);
      //
      //   setTotalGMV(total);
      //   // Get the customer count
      //   let external_customer_url = store_id === null || store_id === '' ? external_api + '/user/userbystore' : external_api + '/user/userbystore?store=' + storeName;
      //   try{
      //     direct_axios.get(external_customer_url, config ).then(result => {
      //       setTotalCustomer(result.data.data.length);
      //       setAverageOrderValue(total/totalOrder);
      //       setIsLoading(false);
      //     })
      //   }catch(e){
      //     console.log(e);
      //     setIsLoading(false);
      //   }
      //   setIsLoading(false);
      //
      // })
    }catch(e){
      console.log(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row mb-12">
      <div className="row">
        <div className="col-md-4">
          {/*<ValueBox*/}
          {/*    label={'Total Order Value (GMV)'}*/}
          {/*    value={`$${totalGMV>0?totalGMV.toFixed(2):'0.00'}`}*/}
          {/*    loading={isLoading}*/}
          {/*    svgIcon={'bx bx-line-chart'}*/}
          {/*    iconColor={'#FE91DA'}*/}
          {/*/>*/}
          <div className="card text-center">
            <div className="card-header">Total Order Value (GMV)</div>
            <div className="card-body">
              {!isLoading?
              <h1>${totalGMV>0?totalGMV.toFixed(2):'0.00'}</h1>:
                <h3 className="card-title mb-2">
                  <div className="demo-inline-spacing">
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
              </h3>
              }
              <p className="text-success">+5.2%</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-header">Average Order Value</div>
            <div className="card-body">
            {!isLoading?
              <h1>${averageOrderValue>0?averageOrderValue.toFixed(2):'0.00'}</h1>:
                <h3 className="card-title mb-2">
                  <div className="demo-inline-spacing">
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
              </h3>
              }
              <p className="text-success">+2.5%</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-header">Sustainability Impact</div>
            <div className="card-body">
              <h1 className="coming-soon">5,960 kg/CO2</h1>
              <p>3,072 packages kept from landfill</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <h1 className="coming-soon">45%</h1>
              <p>Product Conversion Rate</p>
              <span className="arrow">&#x2191;</span>
            </div>
          </div>
        </div>
        {/*<div className="col-md-4">*/}
        {/*  <div className="card text-center">*/}
        {/*    <div className="card-body">*/}
        {/*      <h1 className="coming-soon">6.2%</h1>*/}
        {/*      <p>Cart Abandonment Rate</p>*/}
        {/*      <span className="arrow">&#x2192;</span>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="col-md-4">*/}
        {/*  <div className="card text-center">*/}
        {/*    <div className="card-body">*/}
        {/*      <h1 className="coming-soon">0.9%</h1>*/}
        {/*      <p>Product Service Rate</p>*/}
        {/*      <span className="arrow">&#x2192;</span>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
            {!isLoading?
              <h1>{totalCustomer}</h1>:
                <h3 className="card-title mb-2">
                  <div className="demo-inline-spacing">
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
              </h3>
              }
              <p>Customers</p>
              <span className="arrow">&#x2193;</span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <h1 className="coming-soon">147</h1>
              <p>Indirect Customer Acquisition</p>
              <span className="arrow">&#x2191;</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <h1 className="coming-soon">1.2k</h1>
              <p>Network Shared Products</p>
              <span className="arrow">&#x2191;</span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <h1 className="coming-soon">2.2k</h1>
              <p>Transferred Products</p>
              <span className="arrow">&#x2191;</span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <h1 className="coming-soon">$9,555.00</h1>
              <p>Indirect GMV Added</p>
              <span className="arrow">&#x2191;</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default GifthintValue
