import direct_axios from 'axios';
import axios from "./axios";

const token = localStorage.getItem('accessToken');
const config = {headers: {Authorization: `Bearer ${token}`}};

const external_api = process.env.REACT_APP_EXTERNAL_API_ENDPOINT;
const defaultPagination = {
    page: 1,
    limit: 9999
};

export async function upsertSuggestedHints(storeUuid, payload) {
    const url = new URL(external_api + '/shopify-hint/suggested-hints/' + storeUuid);

    const result = await direct_axios.post(url.toString(), payload, config);
    return result.data;
}

