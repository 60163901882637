import {useState} from "react"
import axios from "../api/axios";
import {useLocation, useNavigate} from 'react-router-dom';
import toast from "react-hot-toast";

const ResetPassword = () => {
    const navigate = useNavigate();

    const [isOnProcess, setIsOnProcess] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('t');

    const handleSaveChanges = async (event) => {
        event.preventDefault();
        setIsOnProcess(true);

        if (!newPassword.trim() || !confirmPassword.trim()) {
            window.toastr.error('Fields are required.', 'Error!');
            setIsOnProcess(false);  // Re-enable the submit button
            return;
        }

        const data = {
            'new_password': newPassword,
            'confirm_password': confirmPassword,
            'token': token
        };

        try {
            await axios.post('/api/reset-password', data);
            window.toastr.success('Password has been updated.', 'Success!');
            navigate("/login");
        } catch (e) {
            console.error('Error:', e);
            window.toastr.error('Error, please try again later.', 'Error!');
        } finally {
            setIsOnProcess(false);  // Re-enable the submit button
        }
    }

    return (
        <div className="container-xxl align-items-center">
            <div className="authentication-wrapper authentication-basic container-p-y login-form-center">
                <div className="authentication-inner">
                    <div className="card">
                        <div className="card-body">
                            <div className="app-brand justify-content-center">
                                <a href="/login" className="app-brand-link gap-2">
                              <span className="app-brand-logo demo">
                                <div className="logo-img text-center">
                                    <img className="app-brand-logo-app" alt="" src="../gyfhint-logo.png"></img>
                                </div>
                              </span>
                                </a>
                            </div>
                            <br/>
                            <h5 className="mb-4">Reset Your Password</h5>
                            <form id="formUpdatePassword" onSubmit={handleSaveChanges}>
                                <div className="mb-3 col-md-12">
                                    <label for="new password" className="form-label">New Password</label>
                                    <input
                                        className="form-control"
                                        type="password"
                                        id="new_password"
                                        name="new_password"
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"/>
                                </div>
                                <div className="col-md-12 mb-5">
                                    <label htmlFor="confirm password" className="form-label">Confirm Password</label>
                                    <input
                                        className="form-control"
                                        type="password"
                                        id="confirm_password"
                                        name="confirm_password"
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"/>
                                </div>

                                {!isOnProcess ?
                                    <button className="btn btn-primary d-grid w-100" type="submit"
                                            onClick={handleSaveChanges}>Submit</button> :
                                    <button className="btn btn-primary d-grid w-100" disabled
                                            type="submit">Loading...</button>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword
