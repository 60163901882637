import direct_axios from 'axios';
import axios from "./axios";

const token = localStorage.getItem('accessToken');
const config = {headers: {Authorization: `Bearer ${token}`}};

const external_api = process.env.REACT_APP_EXTERNAL_API_ENDPOINT;
const defaultPagination = {
    page: 1,
    limit: 9999
};

export async function getMerchantAffiliates(id, options = {}) {
    const url = new URL(external_api + '/merchant-affiliates');

    const params = {...defaultPagination, ...options};
    for (const key of Object.keys(params)) {
        url.searchParams.append(key, params[key]);
    }

    const result = await direct_axios.get(url.toString(), config);
    return result.data;
}

export async function createMerchantAffiliate(payload) {
    const url = new URL(external_api + '/merchant-affiliates');

    const result = await direct_axios.post(url.toString(), payload, config);
    return result.data;
}

export async function updateMerchantAffiliate(payload, id) {
    const url = new URL(external_api + '/merchant-affiliates/' + id);

    const data = {...payload}
    delete data.merchant_affiliate_id;
    delete data.created_at;
    delete data.updated_at;

    const result = await direct_axios.put(url.toString(), data, config);
    return result.data;
}
