import direct_axios from 'axios';
import axios from "./axios";

const token = localStorage.getItem('accessToken');
const config = {headers: {Authorization: `Bearer ${token}`}};

const external_api = process.env.REACT_APP_EXTERNAL_API_ENDPOINT;
const external_shopify_api = process.env.REACT_APP_EXTERNAL_SHOPIFY_API_ENDPOINT;
const defaultPagination = {
    page: 1,
    limit: 9999
};

export async function getPublicStoreUpgradeLink(storeDomain) {
    // https://app-shopify.gyfthint.com/gyfthint/plan?shop=gyfthint-public-app-store.myshopify.com
    const url = new URL(external_shopify_api + '/gyfthint/plan');
    url.searchParams.append('shop', storeDomain);
    return url.toString();
}
