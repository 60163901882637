import { useState } from "react"
import axios from "../api/axios";
import { useLocation } from 'react-router-dom';
import toast from "react-hot-toast";
//import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isOnProcess, setIsOnProcess]=useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  //const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hmac = queryParams.get('hmac');

  const handleLogin = async(event) => {
    event.preventDefault();
    setIsOnProcess(true);
    try{
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/login', {email, password}).then(result =>{
          setEmail("");
          setPassword("");
          localStorage.setItem('accessToken', result.data.token);
          localStorage.setItem('userId', result.data.user_id);
          localStorage.setItem('id', result.data.id);
          localStorage.setItem('profileRole', result.data.menu_access[0]['role_name']);
          localStorage.setItem('menuAccess', JSON.stringify(result.data.menu_access));
          localStorage.setItem('currentModule', 'Dashboard');
          localStorage.setItem('stores', JSON.stringify(result.data.stores));
          localStorage.setItem('role', result.data.role);
          console.log('Store Details');
          console.log(result.data.stores);
          if(result.data.stores.length > 0){
            localStorage.setItem('currentActiveStoreId', result.data.stores[0].uuid);
            localStorage.setItem('currentActiveStoreLicense', result.data.stores[0].label);
            localStorage.setItem('currentActiveStore', result.data.stores[0].store_name);
            localStorage.setItem('currentActiveStoreLogo', result.data.stores[0].store_logo);
          } else {
            localStorage.setItem('currentActiveStoreId', '');
            localStorage.setItem('currentActiveStoreId', '');
            localStorage.setItem('currentActiveStoreLicense', '');
            localStorage.setItem('currentActiveStore', null);
            localStorage.setItem('currentActiveStoreLogo', null);
          }
          window.location.href = '/';
        }, (err)=>{
          if(err.response.data.message==='Unauthenticated.'){
              window.location.href = '/login';
          } else {
            alert(err.response.data.message);
          }
          setIsOnProcess(false);
        });
      });
    } catch (e){
      console.log(e);
      setIsOnProcess(false);
    }
  }
  const handleSaveChanges = async (event) => {
    event.preventDefault();
    //const id = localStorage.getItem('id')!==null?localStorage.getItem('id'):0;
    const data = {
      //'id' : id,
      //'email': email,
      'old_password': 'test',
      'new_password': newPassword,
      'confirm_password': confirmPassword,
      'hmac':hmac
    };

    try{
      await axios.post('/api/users/update-password-hmac', data).then(result=>{
        toast.success("Password changed!");
        window.location.href = '/login';
      }, (err)=>{
        console.log(err.response.data.message);
        if(err.response.data.message==='Unauthenticated.'){
            window.location.href = '/login';
        } else {
          // alert(err.response.data.message);
          toast.error(err.response.data.message);
        }
      });
    } catch(e){
      console.log(e);
    }

  }
  return (
    <div className="container-xxl align-items-center">
      <div className="authentication-wrapper authentication-basic container-p-y login-form-center">
        <div className="authentication-inner">
          <div className="card">
            <div className="card-body">
              <div className="app-brand justify-content-center">
                <a href="/login" className="app-brand-link gap-2">
                  <span className="app-brand-logo demo">
                    <div className="logo-img text-center">
                        <img className="app-brand-logo-app" alt="" src="../gyfhint-logo.png"></img>
                    </div>
                  </span>
                </a>
              </div>
              <br/>
              <h5 className="mb-2">Welcome to GyftHint! 👋</h5>
              <p className="mb-4">Change your password.</p>

              <form id="formUpdatePassword" onSubmit={handleSaveChanges}>
                <div className="mb-3 col-md-6 d-none">
                    <label for="email" className="form-label">Old Password</label>
                    <input
                      className="form-control"
                      type="password"
                      id="old_password"
                      name="old_password"
                      value="test"
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" />
                </div>
                <div className="mb-3 col-md-12">
                    <label for="new password" className="form-label">New Password</label>
                    <input
                      className="form-control"
                      type="password"
                      id="new_password"
                      name="new_password"
                      onChange={(e)=>setNewPassword(e.target.value)}
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" />
                </div>
                <div className="mb-3 col-md-12">
                    <label for="confirm password" className="form-label">Confirm Password</label>
                    <input
                      className="form-control"
                      type="password"
                      id="confirm_password"
                      name="confirm_password"
                      onChange={(e)=>setConfirmPassword(e.target.value)}
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" />
                </div>

                { !isOnProcess?
                    <button className="btn btn-primary d-grid w-100" type="submit" onClick={handleSaveChanges}>Submit</button>:
                    <button className="btn btn-primary d-grid w-100" disabled type="submit">Loading...</button>
                  }
              </form>

              <p className="text-center d-none">
                <span>New on our platform?</span>
                <a href="/merchant-signup">
                  <span>Create an account</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
