import React, {useState, useEffect, useMemo, useRef} from "react";
import {createAffiliateGroup, getAffiliateGroups, updateAffiliateGroup} from "../api/affiliateGroups";
import {getModuleDetails} from "../api/module";
import DataTable from "react-data-table-component";
import FilterDatatable from "../components/FilterDatatable";
import toast from 'react-hot-toast'

const AffiliateGroups = () => {
    /* token and headers*/
    const token = localStorage.getItem('accessToken');
    if (token === '' || token === undefined || token === null) {
        localStorage.setItem('accessToken', '');
        localStorage.setItem('menuAccess', []);
        localStorage.setItem('profileRole', '');
        window.location.href = '/login';
    }

    /* session details */
    const role = localStorage.getItem('profileRole');
    const userId = localStorage.getItem('userId');

    /* page data */
    const addEditModalRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [module, setModule] = useState(null);
    const [originalList, setOriginalList] = useState([]);
    const [list, setList] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [viewSelectedRow, setViewSelectedRow] = useState(false);
    // Search Filter
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [currentFilters, setCurrentFilters] = useState({search: ''});

    const columns = [
        {
            name: "NAME",
            cell: (row) => <span className='text-wrap'>{row.affiliate_group_name || ''}</span>,
        },
        {
            name: "WEBSITE",
            cell: (row) => <span className='text-wrap'>{row.affiliate_group_website || ''}</span>,
        },
        {
            name: "API KEY",
            cell: (row) => <span className='text-wrap'>{row.api_key || ''}</span>,
        },
        {
            name: "API TOKEN",
            cell: (row) => <span className='text-wrap'>{row.api_token || ''}</span>,
        },
        {
            name: "CITY",
            cell: (row) => <span className='text-wrap'>{row.city || ''}</span>,
        },
        {
            name: "COMMAND",
            cell: (row) => <span className='text-wrap'>{row.command || ''}</span>,
        },
        {
            name: "COUNTRY",
            cell: (row) => <span className='text-wrap'>{row.country || ''}</span>,
        },
        {
            name: "CREATED DATE",
            cell: (row) => <span className='text-wrap'>{formatDate(row.created_at)}</span>,
        },
        {
            name: "GH AFFILIATE GROUP ID",
            cell: (row) => <span className='text-wrap'>{row.gh_affiliate_group_id || ''}</span>,
        },
        {
            name: "PAYMENT DAYS",
            cell: (row) => <span className='text-wrap'>{row.payment_days || ''}</span>,
        },
        {
            name: "PAYMENT METHOD",
            cell: (row) => <span className='text-wrap'>{row.payment_method || ''}</span>,
        },
        {
            name: "PAYMENT MIN",
            cell: (row) => <span className='text-wrap'>{row.payment_min || ''}</span>,
        },
        {
            name: "PAYMENT CODE",
            cell: (row) => <span className='text-wrap'>{row.postal_code || ''}</span>,
        },
        {
            name: "PRIMARY ADMIN EMAIL",
            cell: (row) => <span className='text-wrap'>{row.primary_admin_email || ''}</span>,
        },
        {
            name: "PRIMARY ADMIN NAME",
            cell: (row) => <span className='text-wrap'>{row.primary_admin_name || ''}</span>,
        },
        {
            name: "STATE / PROVINCE",
            cell: (row) => <span className='text-wrap'>{row.state_or_province || ''}</span>,
        },
        {
            name: "STREET",
            cell: (row) => <span className='text-wrap'>{row.street || ''}</span>,
        },
        {
            name: "SUPPORT CONTACT EMAIL",
            cell: (row) => <span className='text-wrap'>{row.support_contact_email || ''}</span>,
        },
        {
            name: "SUPPORT CONTACT NAME",
            cell: (row) => <span className='text-wrap'>{row.support_contact_name || ''}</span>,
        },
        {
            name: "SUPPORT CONTACT PHONE",
            cell: (row) => <span className='text-wrap'>{row.support_contact_phone || ''}</span>,
        },
        {
            name: "UPDATED DATE",
            cell: (row) => <span className='text-wrap'>{formatDate(row.updated_at)}</span>,
        },
        {
            name: "",
            cell: (row) => <div>
                <a className='me-3' href="#" onClick={() => {
                    setSelectedRow(row);
                }} data-bs-toggle="modal" data-bs-target="#addEditRow">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 9.49998V12H2.5L9.87333 4.62665L7.37333 2.12665L0 9.49998ZM11.8067 2.69332C11.8685 2.63164 11.9175 2.55838 11.951 2.47773C11.9844 2.39708 12.0016 2.31063 12.0016 2.22331C12.0016 2.136 11.9844 2.04955 11.951 1.9689C11.9175 1.88825 11.8685 1.81499 11.8067 1.75331L10.2467 0.193315C10.185 0.131512 10.1117 0.0824806 10.0311 0.0490263C9.95043 0.015572 9.86398 -0.00164795 9.77667 -0.00164795C9.68935 -0.00164795 9.6029 0.015572 9.52225 0.0490263C9.4416 0.0824806 9.36834 0.131512 9.30667 0.193315L8.08667 1.41331L10.5867 3.91331L11.8067 2.69332Z"
                            fill="#121872"/>
                    </svg>
                </a>
                {/*<a href="#" onClick={() => {*/}
                {/*    setSelectedRow(row)*/}
                {/*}} data-bs-toggle="modal" data-bs-target="#deletePromo">*/}
                {/*    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*        <path*/}
                {/*            d="M2.16675 12C1.80008 12 1.4863 11.8696 1.22541 11.6087C0.964526 11.3478 0.833859 11.0338 0.833415 10.6667V2H0.166748V0.666667H3.50008V0H7.50008V0.666667H10.8334V2H10.1667V10.6667C10.1667 11.0333 10.0363 11.3473 9.77542 11.6087C9.51453 11.87 9.20053 12.0004 8.83342 12H2.16675ZM3.50008 9.33333H4.83342V3.33333H3.50008V9.33333ZM6.16675 9.33333H7.50008V3.33333H6.16675V9.33333Z"*/}
                {/*            fill="#121872"/>*/}
                {/*    </svg>*/}
                {/*</a>*/}
            </div>,
        }
    ];

    useEffect(() => {
        setIsLoading(true);
        loadPageData();
    }, []);

    const loadPageData = async () => {
        const [module, affiliateGroups] = await Promise.all([
            getModuleDetails(userId, window.location.pathname),
            getAffiliateGroups(),
        ])
        setModule(module.menu_access[0]);
        setOriginalList(affiliateGroups.data)
        setList(affiliateGroups.data);
        setIsLoading(false);
    }

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterDatatable
                onFilter={e => {
                    setCurrentFilters({
                        ...currentFilters,
                        search: e.target.value,
                    });
                    setFilterText(e.target.value);
                }}
                onClear={handleClear}
                filterText={filterText}
                placeholder={'Search label'}
            />
        );
    }, [filterText, resetPaginationToggle]);

    // Automatically update table rows based on filters
    useEffect(() => {
        let tempList = [...originalList];
        const filters = Object.keys(currentFilters);
        for (let i = 0; i < filters.length; i++) {
            const filterType = filters[i];
            const filterValue = currentFilters[filterType];

            if (filterType === 'search') {
                if (filterValue) {
                    const normalizedFilter = filterValue.toLowerCase();
                    tempList = tempList.filter(row => {
                        const name = row.affiliate_group_name;
                        const website = row.affiliate_group_website;
                        if (!name && !website) return false;
                        return (name.toLowerCase().includes(normalizedFilter) || website.includes(normalizedFilter));
                    });
                }
            }
        }
        setList(tempList);
    }, [currentFilters, originalList]);

    function formatDate(date) {
        const d = new Date(date);
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${month}/${day}/${year}`;
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setSelectedRow((selectedRow) => ({
            ...selectedRow,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSaving(true);

        if (!selectedRow.affiliate_group_name && !selectedRow.affiliate_group_website) {
            toast.error("Required: Affiliate Group Name, Affiliate Group Website & GH Affiliate Group ID.", 'Oops!');
            setIsSaving(false);
            return;
        }

        try {
            if (selectedRow.affiliate_group_id) {
                await updateAffiliateGroup(selectedRow, selectedRow.affiliate_group_id);
            } else {
                await createAffiliateGroup(selectedRow);
            }
            const affiliateGroups = await getAffiliateGroups();
            setOriginalList(affiliateGroups.data);
            setList(affiliateGroups.data);
            if (addEditModalRef.current) window.$(addEditModalRef.current).modal("hide"); // Use jQuery to hide the modal
            if (selectedRow.affiliate_group_id) {
                toast.success("Affiliate group updated!");
            } else {
                toast.success("Affiliate group created!");
            }
        } catch (e) {
            if (selectedRow.affiliate_group_id) {
                console.error("Unable to update affiliate group. Error: ", e);
                toast.error("Unable to update affiliate group.", 'Oops!');
            } else {
                console.error("Unable to create new affiliate group. Error: ", e);
                toast.error("Unable to create new affiliate group.", 'Oops!');
            }
        }
        setIsSaving(false);
    }

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="main-kpis">
            </div>

            <div className="row mb-12">
                <div id="table-list-container" className="col-md-12">
                    <div className="card">
                        <h5 className="card-header">
                            {
                                (module?.can_add === 1) &&
                                <button type="submit"
                                        className="btn btn-primary btn-sm ms-2 mb-2 btn-create-promotion "
                                        data-bs-toggle="modal" data-bs-target="#addEditRow"
                                        onClick={() => setSelectedRow({})}>
                                    Add
                                    {/*&nbsp; <i className="bx bxs-plus-circle gh-h-px-20"></i>*/}
                                </button>
                            }
                            <div className="kpi-card-info">
                                <span>Gyfthint</span>
                                <p>Affiliate Groups</p>
                            </div>
                            <hr/>
                            <div className='d-flex'>
                            </div>
                        </h5>
                        <div className="table-responsive text-nowrap">
                            <DataTable
                                columns={columns}
                                data={list}
                                highlightOnHover
                                // onRowSelected={handleRowSelected}
                                // noDataComponent={<div className="w-100">
                                //     <table className="table">
                                //         <thead>
                                //         <tr>
                                //             {
                                //                 promotion_columns.filter(c => !c.omit).map(c => (<th>{c.name}</th>))
                                //             }
                                //         </tr>
                                //         </thead>
                                //         <tbody>
                                //         <tr>
                                //             <td colSpan={10}>No records found.</td>
                                //         </tr>
                                //         </tbody>
                                //     </table>
                                // </div>}
                                persistTableHead
                                pagination
                                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="addEditRow" tabIndex="-1" aria-hidden="true" ref={addEditModalRef}>
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            <div className="mb-6">
                                <h4 className="mb-2">Affiliate Group Details</h4>
                            </div>
                            <hr/>
                            <form className="row g-6">
                                <div className="col-12 col-md-4">
                                    {
                                        !!(selectedRow.affiliate_group_id) &&
                                        <>
                                            <label className="form-label" htmlFor="affiliate_group_id">
                                                Affiliate Group ID:
                                            </label>
                                            <p>{selectedRow.affiliate_group_id}</p>
                                        </>
                                    }
                                    <label className="form-label" htmlFor="affiliate_group_name">
                                        Affiliate Group Name:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="affiliate_group_name" name="affiliate_group_name"
                                           value={selectedRow.affiliate_group_name || ''} onChange={handleInputChange}/>
                                    <label className="form-label" htmlFor="affiliate_group_website">
                                        Affiliate Group Website:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="affiliate_group_website" name="affiliate_group_website"
                                           value={selectedRow.affiliate_group_website || ''}
                                           onChange={handleInputChange}/>
                                    <label className="form-label" htmlFor="support_contact_name">
                                        Support Contact Name:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="support_contact_name" name="support_contact_name"
                                           value={selectedRow.support_contact_name || ''} onChange={handleInputChange}/>
                                    <label className="form-label" htmlFor="support_contact_phone">
                                        Support Contact Phone:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="support_contact_phone" name="support_contact_phone"
                                           value={selectedRow.support_contact_phone || ''}
                                           onChange={handleInputChange}/>
                                    <label className="form-label" htmlFor="support_contact_email">
                                        Support Contact Email:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="support_contact_email" name="support_contact_email"
                                           value={selectedRow.support_contact_email || ''}
                                           onChange={handleInputChange}/>
                                    <label className="form-label" htmlFor="payment_days">
                                        Payment Days:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="number" className="form-control"
                                           id="payment_days" name="payment_days"
                                           value={selectedRow.payment_days || ''} onChange={handleInputChange}/>
                                    <label className="form-label" htmlFor="payment_min">
                                        Payment Min:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="number" className="form-control"
                                           id="payment_min" name="payment_min"
                                           value={selectedRow.payment_min || ''} onChange={handleInputChange}/>
                                    <label className="form-label" htmlFor="payment_method">
                                        Payment Method:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="payment_method" name="payment_method"
                                           value={selectedRow.payment_method || ''} onChange={handleInputChange}/>
                                </div>
                                <div className="col-12 col-md-4">
                                    {
                                        !!(selectedRow.affiliate_group_id) &&
                                        <>
                                            <label className="form-label" htmlFor="affiliate_group_id">
                                                Created Date:
                                            </label>
                                            <p>{formatDate(selectedRow.created_at)}</p>
                                        </>
                                    }
                                    <label className="form-label" htmlFor="primary_admin_name">
                                        Primary Admin Name:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="primary_admin_name" name="primary_admin_name"
                                           value={selectedRow.primary_admin_name || ''} onChange={handleInputChange}/>
                                    <label className="form-label" htmlFor="primary_admin_email">
                                        Primary Admin Email:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="primary_admin_email" name="primary_admin_email"
                                           value={selectedRow.primary_admin_email || ''} onChange={handleInputChange}/>
                                    <label className="form-label" htmlFor="gh_affiliate_group_id">
                                        GH Affiliate Group ID:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="gh_affiliate_group_id" name="gh_affiliate_group_id"
                                           value={selectedRow.gh_affiliate_group_id || ''}
                                           onChange={handleInputChange}/>
                                    <label className="form-label" htmlFor="street">
                                        Street:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="street" name="street"
                                           value={selectedRow.street || ''} onChange={handleInputChange}/>
                                    <label className="form-label" htmlFor="city">
                                        City:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="city" name="city"
                                           value={selectedRow.city || ''} onChange={handleInputChange}/>
                                    <label className="form-label" htmlFor="state_or_province">
                                        State / Province:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="state_or_province" name="state_or_province"
                                           value={selectedRow.state_or_province || ''} onChange={handleInputChange}/>
                                    <label className="form-label" htmlFor="postal_code">
                                        Postal Code:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="postal_code" name="postal_code"
                                           value={selectedRow.postal_code || ''} onChange={handleInputChange}/>
                                    <label className="form-label" htmlFor="country">
                                        Country:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="country" name="country"
                                           value={selectedRow.country || ''} onChange={handleInputChange}/>
                                </div>
                                <div className="col-12 col-md-4">
                                    {
                                        !!(selectedRow.affiliate_group_id) &&
                                        <>
                                            <label className="form-label" htmlFor="affiliate_group_id">
                                                Updated Date:
                                            </label>
                                            <p>{formatDate(selectedRow.updated_at)}</p>
                                        </>
                                    }
                                    <label className="form-label" htmlFor="api_key">
                                        API Key:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="api_key" name="api_key"
                                           value={selectedRow.api_key} onChange={handleInputChange}/>
                                    <label className="form-label" htmlFor="api_token">
                                        API Token:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="api_token" name="api_token"
                                           value={selectedRow.api_token} onChange={handleInputChange}/>
                                    <label className="form-label" htmlFor="command">
                                        Command:
                                    </label>
                                    <input readOnly={viewSelectedRow} type="text" className="form-control"
                                           id="command" name="command"
                                           value={selectedRow.command} onChange={handleInputChange}/>
                                </div>
                            </form>
                            <hr/>
                            <div className="col-12">
                                {
                                    !viewSelectedRow &&
                                    <>
                                        {
                                            !isSaving ?
                                                <button type="submit" className="btn btn-primary me-3"
                                                        onClick={handleSubmit}>
                                                    {!!(selectedRow.affiliate_group_id) ? 'Update' : 'Create'}
                                                </button>
                                                :
                                                <button type="submit" disabled className="btn btn-primary me-3"
                                                >Saving...</button>
                                        }
                                    </>
                                }
                                <button type="reset" className="btn btn-label-secondary" data-bs-dismiss="modal"
                                        aria-label="Close">Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AffiliateGroups
