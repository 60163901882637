import direct_axios from 'axios';
import axios from "./axios";

const token = localStorage.getItem('accessToken');
const config = {headers: {Authorization: `Bearer ${token}`}};

const external_api = process.env.REACT_APP_EXTERNAL_API_ENDPOINT;
const defaultPagination = {
    page: 1,
    limit: 9999
};

export async function getModuleDetails(userId, link) {
    const payload = {'uuid': userId, 'module_nav_link': link}
    const result = await axios.post('/api/module-access-details', payload, config);
    return result.data;
}
