import direct_axios from 'axios';
import axios from "./axios";

const token = localStorage.getItem('accessToken');
const config = {headers: {Authorization: `Bearer ${token}`}};

const external_api = process.env.REACT_APP_EXTERNAL_API_ENDPOINT;

export async function getDashboardGraphData(storeId, options) {
    const url = new URL(external_api + '/dashboard/graph');

    if (storeId) url.searchParams.append("storeId", storeId);

    const result = await direct_axios.get(url.toString(), config);
    return result.data;
}
