import React, {useEffect, useState} from "react";
import Chart from "../components/Chart";
import {useLocation} from 'react-router-dom';
import ValueBox from "../components/ValueBox";
import {getAffiliateCodesByStoreUuid} from "../api/affiliateCodes";
import {getHintsByStoreUuid} from "../api/hints";
import {getOrdersByStoreUuid} from "../api/orders";
import {formatPrice, formatQty} from "../helpers/number";
import {getDashboardGraphData} from "../api/dashboard";

const Dashboard = () => {
    /* token and headers*/
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const hmac = queryParams.get('hmac');

    const [isLoading, setIsLoading] = useState(false);

    const [totalProduct, setTotalProduct] = useState(0);
    const [totalGMV, setTotalGMV] = useState(0);
    const [totalComission, setTotalComission] = useState(0);
    const [totalOrder, setTotalOrder] = useState(0);
    const [avgOrderValue, setAvgOrderValue] = useState(0);

    const [role, setRole] = useState(localStorage.getItem('profileRole'));

    const currentActiveStoreId = localStorage.getItem('currentActiveStoreId');
    const currentActiveStoreName = localStorage.getItem('currentActiveStore');

    const [storeId, setStoreId] = useState(role === 'merchant' ? currentActiveStoreId : null);
    const [storeName, setStoreName] = useState(role === 'merchant' ? currentActiveStoreName : null);

    const [graphData, setGraphData] = useState([]);

    /* token and headers */
    const token = localStorage.getItem('accessToken');
    if (token === '' || token === undefined || token === null) {
        localStorage.setItem('accessToken', '');
        localStorage.setItem('menuAccess', []);
        localStorage.setItem('profileRole', '');
        window.location.href = '/login';
    }

    if (hmac !== null) {
        window.location.href = '/change-password?hmac=' + hmac;
    }

    useEffect(() => {
        fetchTable();
        setIsLoading(true);
    }, []);

    const fetchTable = async () => {
        // Set loading state and clear previous orders
        setIsLoading(true);

        console.log('START WITH THIS STORE ID', storeId);

        try {
            const [affiliateData, hints, orders, graph] = await Promise.all([
                getAffiliateCodesByStoreUuid(storeId),
                getHintsByStoreUuid(storeId, role === 'merchant' ? {merchant: '1'} : {}),
                getOrdersByStoreUuid(storeId, role === 'merchant' ? {status: 'PURCHASED'} : {}),
                getDashboardGraphData(storeId),
            ])

            setGraphData(graph.data);

            setTotalProduct(hints.data.hints.length);

            const total = orders.data.reduce((sum, product) => {
                // Check if the product's hint is not 'CANCEL' before adding its price
                if (product.status !== 'CANCEL') {
                    return sum + product.hint.price;
                }
                return sum;
            }, 0);

            let totalComission = 0;
            if (affiliateData.data && affiliateData.data.length && affiliateData.data[0].affiliate_commission_percentage) {
                totalComission = (Number(affiliateData.data[0].affiliate_commission_percentage) / 100) * total;
            }

            setTotalOrder(orders.data.length > 0 ? orders.data.length : 0);
            setTotalGMV(total);
            setAvgOrderValue(total / (orders.data.length > 0 ? orders.data.length : 0))
            setTotalComission(totalComission);
            setIsLoading(false);

        } catch (e) {
            // Handle error by logging it and resetting loading state
            console.log(e);
            setIsLoading(false);
        }
    };

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-lg-12 mb-4 order-0">
                    <div className="card">
                        <div className="d-flex align-items-end row">
                            <div className="col-sm-10">
                                <div className="card-body">
                                    <h5 className="card-title text-primary">Welcome {localStorage.getItem('profileName')}!
                                        🎉</h5>
                                    <p className="mb-4">
                                        The <b>GyftHint Retail Interface Platform (GRIP<sup>TM</sup>)</b> provides you
                                        with secure access and tools to manage your account with GyftHint, including:
                                        <ol>
                                            <li>Viewing and updating portal licenses and users</li>
                                            <li>Viewing and updating Suggested Products (a unique feature to your
                                                relationship with GyftHint - these products will be added as a Suggested
                                                Hint to your customers when a customer first clicks on the Add to
                                                GyftHint universal wishlist button on your site),
                                            </li>
                                            <li>Viewing product activity and conversion for your brand through
                                                GyftHint:
                                            </li>
                                            <ul>
                                                <li>Hints - are products added to individual user stores for purchase by
                                                    their network of buyers or themselves
                                                </li>
                                                <li>Orders - are completed purchases made by buyers from your customers'
                                                    stores
                                                </li>
                                                <li>Commissions - are details on the affiliate commissions payable for
                                                    those referred orders
                                                </li>
                                            </ul>
                                            <li>Understanding your value received from the platform through dashboard
                                                analytics and easy to understand performance metrics
                                            </li>
                                            <li>Promotion capability (coming soon) - with certain license upgrades,
                                                being provided with the ability to drive unique promotions on your
                                                products within the personal stores on GyftHint, enabling you to
                                                highlight and differentiate your products to influence buyer behaviors
                                                and conversion
                                            </li>
                                            <li>Data as a Service (DaaS) offering (coming soon) - providing detailed
                                                industry and trending product and category analytics and brand
                                                performance on GyftHint
                                            </li>
                                        </ol>
                                    </p>
                                    {localStorage.getItem('profileRole').toLowerCase() === 'merchant' &&
                                        <a className="btn btn-sm btn-primary" href={'/suggested-hints'}>Update Your
                                            Suggested Products</a>
                                    }
                                </div>
                            </div>
                            <div className="col-sm-2 text-center text-sm-left p-0">
                                <div className="card-body pb-0 px-0 d-flex">
                                    <img
                                        src="../assets/img/illustrations/man-with-laptop-light.png"
                                        height="140"
                                        alt="View Badge User"
                                        data-app-dark-img="illustrations/man-with-laptop-dark.png"
                                        data-app-light-img="illustrations/man-with-laptop-light.png"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row w-100 m-0 justify-content-between mb-4">
                    <ValueBox
                        label={'Products'}
                        // value={totalProduct > 0 ? totalProduct.toLocaleString() : 0}
                        value={totalProduct > 0 ? formatQty(totalProduct) : 0}
                        loading={isLoading}
                        icon={'tf-icons bx bx-gift rounded'}
                        iconColor={'#2563EB'}
                        // updates={{
                        //     increase: true,
                        //     value: 28,
                        // }}
                        // actions={[
                        //     {
                        //         label: 'View More',
                        //         onClick: () => {
                        //             // do nothing
                        //         },
                        //     },
                        //     {
                        //         label: 'Delete',
                        //         onClick: () => {
                        //             // do nothing
                        //         },
                        //     }
                        // ]}
                    />
                    <ValueBox
                        label={'Orders'}
                        value={totalOrder > 0 ? formatQty(totalOrder) : 0}
                        loading={isLoading}
                        icon={'tf-icons bx bx-package'}
                        iconColor={'#C084FC'}
                        // updates={{
                        //     increase: true,
                        //     value: 21,
                        // }}
                        // actions={[
                        //     {
                        //         label: 'View More',
                        //         onClick: () => {
                        //             // do nothing
                        //         },
                        //     },
                        //     {
                        //         label: 'Delete',
                        //         onClick: () => {
                        //             // do nothing
                        //         },
                        //     }
                        // ]}
                    />
                    <ValueBox
                        label={'(AOV) Average Order Value'}
                        // value={`$${avgOrderValue > 0 ? avgOrderValue.toFixed(2) : '0.00'}`}
                        value={`$${avgOrderValue > 0 ? formatPrice(avgOrderValue) : '0.00'}`}
                        loading={isLoading}
                        icon={'tf-icons bx bx-package'}
                        iconColor={'#FFCC26'}
                        // updates={{
                        //     increase: false,
                        //     value: 2,
                        // }}
                        // actions={[
                        //     {
                        //         label: 'View More',
                        //         onClick: () => {
                        //             // do nothing
                        //         },
                        //     },
                        //     {
                        //         label: 'Delete',
                        //         onClick: () => {
                        //             // do nothing
                        //         },
                        //     }
                        // ]}
                    />
                    <ValueBox
                        label={'Returns'}
                        value={'0%'}
                        loading={isLoading}
                        icon={'tf-icons bx bx-share'}
                        iconColor={'#FF87AD'}
                        // updates={{
                        //     increase: false,
                        //     value: 2,
                        // }}
                        comingSoon={true}
                        // actions={[
                        //     {
                        //         label: 'View More',
                        //         onClick: () => {
                        //             // do nothing
                        //         },
                        //     },
                        //     {
                        //         label: 'Delete',
                        //         onClick: () => {
                        //             // do nothing
                        //         },
                        //     }
                        // ]}
                    />
                    <ValueBox
                        label={'Commissions'}
                        // value={`$${totalComission > 0 ? totalComission.toFixed(2) : '0.00'}`}
                        value={`$${totalComission > 0 ? formatPrice(totalComission) : '0.00'}`}
                        loading={isLoading}
                        icon={'tf-icons bx bx-money'}
                        iconColor={'#74E86E'}
                        // updates={{
                        //     increase: true,
                        //     value: '15%',
                        // }}
                        // actions={[
                        //     {
                        //         label: 'View More',
                        //         onClick: () => {
                        //             // do nothing
                        //         },
                        //     },
                        //     {
                        //         label: 'Delete',
                        //         onClick: () => {
                        //             // do nothing
                        //         },
                        //     }
                        // ]}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6 d-none">
                    <div className="top-pages col-dash-card">
                        <h2>Orders</h2>
                        <table>
                            <thead>
                            <tr>
                                <th>Page Path</th>
                                <th>Page Views</th>
                                <th>Sessions</th>
                                <th>New Users</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>/home</td>
                                <td>10,653</td>
                                <td>7,456</td>
                                <td>2,456</td>
                            </tr>
                            <tr>
                                <td>/shop</td>
                                <td>10,273</td>
                                <td>7,364</td>
                                <td>2,164</td>
                            </tr>
                            <tr>
                                <td>/catalog</td>
                                <td>9,367</td>
                                <td>6,853</td>
                                <td>1,953</td>
                            </tr>
                            <tr>
                                <td>/about</td>
                                <td>9,157</td>
                                <td>6,273</td>
                                <td>1,573</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="rates-chart col-dash-card">
                        <div className="kpi-card-info">
                            <span>Gyfthint</span>
                            <p>Products & Orders</p>
                        </div>
                        <Chart graphData={graphData}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
