import React, {useState} from 'react';
import axios from "../api/axios";

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isOnProcess, setIsOnProcess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsOnProcess(true);  // Disable the submit button to avoid multiple submissions

        // Validate email
        if (!email.trim()) {
            window.toastr.error('Email required.', 'Error!');
            setIsOnProcess(false);  // Re-enable the submit button
            return;
        }

        try {
            const response = await axios.post('/api/forgot-password', {email});
            window.toastr.success('Reset password link has been sent to your email.', 'Success!');
        } catch (error) {
            console.error('Error:', error);
            window.toastr.error('Error, please try again later.', 'Error!');
        } finally {
            setIsOnProcess(false);  // Re-enable the submit button
        }
    };

    return (
        <div className="container-xxl align-items-center">
            <div className="authentication-wrapper authentication-basic container-p-y login-form-center">
                <div className="authentication-inner">
                    <div className="card">
                        <div className="card-body">
                            <div className="app-brand justify-content-center">
                                <a href="/login" className="app-brand-link gap-2">
                              <span className="app-brand-logo demo">
                                <div className="logo-img text-center">
                                    <img className="app-brand-logo-app" alt="" src="../gyfhint-logo.png"></img>
                                </div>
                              </span>
                                </a>
                            </div>
                            <br/>
                            <h5 className="mb-4">Forgot Password</h5>
                            <form id="formUpdatePassword" onSubmit={handleSubmit}>
                                <div className="col-md-12 mb-5">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        type="text"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} // Update the subject state
                                        className="form-control"
                                        id="email"
                                        name="email-username"
                                        placeholder="Enter your email"
                                        required
                                        autoFocus/>
                                </div>
                                {!isOnProcess ?
                                    <button className="btn btn-primary d-grid w-100" type="submit"
                                            onClick={handleSubmit}>Submit</button> :
                                    <button className="btn btn-primary d-grid w-100" disabled
                                            type="submit">Loading...</button>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;
